* {
  box-sizing: border-box;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; 
}
body {
  margin: 0;
  padding: 0;
  font-display: auto;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {

}
#splash {
  background: url("./logo.svg") no-repeat center;
  z-index: 1001;
  transition: opacity 300ms ease-in;
  opacity: 1;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
}
@media(min-width: 770px) {
  *::-webkit-scrollbar {
    width: 4px;
    border-radius: 16px;
  }
  *::-webkit-scrollbar-track {
    /* -webkit-box-shadow: inset 0 0 0 rgba(0,0,0,0.9); */
    background-color: #E112;
  }
  *::-webkit-scrollbar-thumb {
    background-color: #E91E63;
    outline: 0 solid transparent;
  }
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
